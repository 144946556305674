<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_deposit_withdraw.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('log_deposit_withdraw.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :date="dateFrom" :label="$t('log_deposit_withdraw.date_from')"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :date="dateTo" :label="$t('log_deposit_withdraw.date_to')" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="findTransaction"> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="transactions"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-if="item.type === 'deposit'" class="success--text">
                <v-icon color="success" small>mdi-arrow-up</v-icon>
                ฝาก
              </div>
              <div v-else class="error--text">
                <v-icon color="error" small>mdi-arrow-down</v-icon>
                ถอน
              </div>
            </template>
            <template v-slot:[`item.before_amount`]="{ item }">
              {{ item.before_amount | currency }}
            </template>
            <template v-slot:[`item.after_amount`]="{ item }">
              {{ item.amount | currency }} / {{ item.after_amount | currency }}
            </template>
            <template v-slot:[`item.admin.username`]="{ item }">
              <span v-if="item.admin">{{ item.admin.username }}</span>
              <span v-else>AUTO</span>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
      <staff-show-detail :dialog="showDetail" :username="currentMemberDetail.username"
        :password="currentMemberDetail.password" @setDialog="showDetail = $event" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StaffShowDetail from '@/views/staff/StaffShowDetail.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
    StaffShowDetail,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: '#',
          value: 'id',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header1'),
          value: 'member.phone',
          width: '100px',
          sortable: false,
        },

        {
          text: this.$t('log_deposit_withdraw.header2'),
          value: 'before_amount',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header3'),
          value: 'after_amount',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header4'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header5'),
          value: 'promotion.name',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header6'),
          value: 'detail',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header7'),
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_deposit_withdraw.header8'),
          value: 'admin.username',
          align: 'center',
          sortable: false,
        },
      ],
      transactions: [],
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont && !this.dummy) {
          this.loading = true
          const result = this.searchData ?
            await this.getLogDepositWithdrawData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogDepositWithdrawData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.setDataTransection(this.filerDate(result.data))
          this.pagination.totalItems = result.count
          this.loading = false
        }
        if (cont && this.dummy) {
          this.loading = true
          const result = this.searchData ?
            await this.getLogDepositWithdrawData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogDepositWithdrawData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.setDataTransection(this.filerDate(result.data))
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'Logs ฝากถอน',
            url: window.location.href,
            detail: 'รายการ Logs (ฝาก-ถอน)',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    setDataTransection(data) {
      let res = []
      let resNot = []

      for (let index = 0; index < data.length; index++) {
        if (data[index].detail !== 'เพิ่มเครดิตในกระเป๋าฝาก ผ่าน ระบบออโต้ธนาคาร') {
          res.push(data[index])
        } else {
          resNot.push(data[index])
        }
      }

      // console.log('data_not_show: ', resNot);
      this.transactions = res
    },
    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    async findTransaction() {
      this.loading = true
      this.dummy = false;
      let params = this.searchData ?
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}` :
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogDepositWithdrawData(params)
      this.pagination.totalItems = result.count
      this.setDataTransection(this.filerDate(result.data))
      this.pagination.page = 1
      this.loading = false
    },
    filerDate(data) {
      let dataFill = []
      data.length > 0 && data?.forEach(el => { el && dataFill.push(el) })
      return dataFill
    },
    async getLogDepositWithdrawData(params) {
      try {
        const res = await this.$store.dispatch('getLogDepositWithdraw', params)
        return res
      } catch (e) { }
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      this.loading = true
      let result = {}
      if (value) {
        result = await this.getLogDepositWithdrawData(`page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
      } else {
        result = await this.getLogDepositWithdrawData(`page=1&rows=${this.pagination.rowsPerPage}`)
      }
      this.pagination.totalItems = result.count
      this.setDataTransection(this.filerDate(result.data))
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
